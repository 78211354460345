import { useEmploymentTypes } from "@/hooks/useEmploymentTypes";
import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/jobs.json";

export const useJobs = ({
  __consultant = null,
  __sector = null,
  __skill = null,
  __client = null,
  featured = null,
  limit = null
} = {}) => {
  const employment_types = useEmploymentTypes();
  let jobs = useSourceFlowCollection(data.jobs).map((job) => {
    const json = JSON.stringify(job.categories);

    return {
      ...job,
      employment_type: employment_types.find((e) => json.includes(e.id)),
    };
  });

  if (__client) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__client));
  }

  if (__consultant) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__consultant));
  }

  if (__sector) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__sector));
  }
  if (__skill) {
    jobs = jobs.filter((job) => JSON.stringify(job.categories).includes(__skill));
  }

  if (typeof featured === "boolean") {
    jobs = jobs.filter((job) => job.featured === featured);
  }

  if (limit) {
    jobs = jobs.slice(0, limit);
  }

  return jobs;
};

export default useJobs;
